import { render, staticRenderFns } from "./ResultsCards.vue?vue&type=template&id=150a5693&scoped=true"
import script from "./ResultsCards.vue?vue&type=script&lang=js"
export * from "./ResultsCards.vue?vue&type=script&lang=js"
import style0 from "./ResultsCards.vue?vue&type=style&index=0&id=150a5693&prod&scoped=true&lang=scss"
import style1 from "./ResultsCards.vue?vue&type=style&index=1&id=150a5693&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150a5693",
  null
  
)

export default component.exports