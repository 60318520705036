import shuffle from 'lodash/shuffle';
import axios from 'axios';

// Pick words with bigger probability for less known ones
// @TODO: This should be moved somewhere
export function filterSmart(words) {
	const ALL_WORDS_LIMIT = 20;

	words = words.slice();

	// When number of words reasonably small we want to display all words.
	if (words.length <= ALL_WORDS_LIMIT) {
		return words;
	}

	words = shuffle(words);

	const knownNumbers = words.map(word => word.knownNumber);

	const maxKnowledge = Math.max.apply(null, knownNumbers);
	const minKnowledge = Math.min.apply(null, knownNumbers);
	const diffKnowledge = maxKnowledge - minKnowledge;

	// might be 20 because if someone is learning 1000 words,
	// then 800 will be picked
	//
	// but on the other hand if someone is learning 1000 words
	// and we give user random 20 - different each time,
	// how user is going to learn them?
	const numberOfWordsToPick = ALL_WORDS_LIMIT;

	const pickedWords = [];

	while (pickedWords.length < numberOfWordsToPick) {
		const max = words.length;

		for (let i = 0; i < max; i++) {
			const word = words[i];

			const knowledge = word.knownNumber;
			let probabilityModifier = (knowledge - minKnowledge) / diffKnowledge;
			if (isNaN(probabilityModifier)) {
				probabilityModifier = 0.5;
			}
			// 0.5	=> 0
			// 2	=> -3
			// -2	=> 5
			probabilityModifier = (probabilityModifier * -2) + 1;

			// 0	=> 0
			// -3	=> 1,2
			// 5	=> 2
			probabilityModifier *= 0.4;

			if (Math.random() + probabilityModifier > 0.5) {
				pickedWords.push(word);

				words.splice(i, 1);
				break;
			}
		}
	}

	return pickedWords;
}

// Following function fetch data form back-end and normalize

export async function getUserListsFromServer(userId, author = undefined, skip = 0) {
	const { data } = await axios.get('/lists', {
		params: {
			userId,
			limit: 12,
			skip,
		},
	});

	let lists = data;

	// Data normalization
	if (author) {
		lists.forEach((list) => {
			list.author = author;
		});
	}

	return lists;
}

export async function getOthersListsFromServer(skip = 0) {
	let { data } = await axios.get('/lists/others', {
		params: {
			limit: 12,
			skip,
		},
	});

	return data;
}

export function getLearnListsFromServer() {
	return axios.get('/lists/learn').then((res) => {
		let data = res.data;

		// @TODO: Data normalization - normalize in back-end.
		data.forEach((list) => {
			list.author = {
				displayName: list.user,
			};

			delete list.user;
		});

		return data;
	});
}
