<template>
	<div class="c-results-cards">
		<div
			v-if="showCardsConfiguration"
			class="deck-cards-configuration"
			:class="{
				'deck-cards-configuration--knowledge-progress': knowledgeProgress
			}"
		>
			<button
				type="button"
				class="c-btn c-btn--secondary c-btn--small deck-cards-configuration__button"
				:class="{ 'c-btn--inverted': darkBackground }"
				@click="cardsSettingsPopOverOpened = !cardsSettingsPopOverOpened"
			>
				<i class="c-btn__icon sliders horizontal icon" /> {{ $t('displaySettings') }}
			</button>
			<div class="deck-cards-configuration__container">
				<DeckCardsConfiguration
					:sort-by="sortBy"
					:update-sort-by="updateSortBy"
					:progress-visible="progressVisible"
					:toggle-progress-visible="toggleProgressVisible"
					:visible-cards-side="visibleCardsSide"
					:update-visible-cards-side="updateVisibleCardsSide"
					:result-display-type="resultDisplayType"
					:change-result-display-type="changeResultDisplayType"
					:knowledge-progress="knowledgeProgress"
					:toggle-show-answers="toggleShowAnswers"
					:show-answers="showAnswers"
					:disable-controls="disableControlsLocal"
				/>
			</div>
		</div>

		<div
			:class="{
				'c-results-cards__scrollable-content' : scrollable
			}"
		>
			<transition-group
				v-if="resultDisplayType === resultDisplayTypes.CARDS"
				name="flip-list"
				tag="div"
				class="result-cards-grid"
				:class="{ 'result-cards-grid--answers': isChoiceCategoryType }"
			>
				<div
					v-for="(card, index) in questionCardsSorted"
					ref="cards"
					:key="card._id"
					class="c-results-card"
					:style="seed && stylee(card._id)"
					:data-qa="isAnswerCorrect(card) === null ? 'result-card' : `result-card-${isAnswerCorrect(card) ? 'correct' : 'wrong'}`"
				>
					<div
						v-if="isChoiceCategoryType"
						class="c-results-card__inner"
					>
						<slot
							name="aside"
							:card="card"
						/>
						<div
							class="question-card"
							style="position: relative; pointer-events: none;"
						>
							<p class="ui header tiny">
								{{ card.question }}
							</p>
							<div v-if="categoryType === categories.SINGLE_CHOICE">
								<div v-if="answers[card._id]">
									<!-- Should display correct and wrong answer -->
									<AnswersRadio
										v-model="card.validAnswer"
										compact
										:options="answersToOptions(card)"
										:answer="answers[card._id]"
									/>
								</div>
								<div v-else-if="showAnswers">
									<!-- Should display correct answer as selected -->
									<AnswersRadio
										v-model="card.validAnswer"
										compact
										:options="answersToOptions(card)"
										:answer="answers[card._id]"
									/>
								</div>
								<div v-else>
									<!-- Should display nothing. -->
									<AnswersRadio
										compact
										:options="answersToOptions(card)"
									/>
								</div>
							</div>
							<div v-if="categoryType === categories.MULTIPLE_CHOICE">
								<div v-if="answers[card._id] && answers[card._id].length">
									<!-- Should display correct and wrong answer -->
									<AnswersCheckboxes
										v-model="answers[card._id]"
										:valid-answers="answers[card._id] && card.validAnswers"
										compact
										:options="answersToOptions(card)"
									/>
								</div>
								<div v-else-if="showAnswers">
									<!-- Should display correct answer as selected -->
									<AnswersCheckboxes
										v-model="card.validAnswers"
										compact
										:options="answersToOptions(card)"
									/>
								</div>
								<div v-else>
									<!-- Should display nothing. -->
									<AnswersCheckboxes
										compact
										:options="answersToOptions(card)"
									/>
								</div>
							</div>
							<transition name="show">
								<Progress
									v-if="knowledgeProgress && progressVisible"
									style="--c-progress-radius: 8px;"
									:progress="progress(card)"
									:previous-progress="previousProgress(card, index)"
									:delay-animation="delayAnimation(card, index)"
								/>
							</transition>
						</div>
					</div>
					<SwingCard
						v-else
						compact-fonts
						:word="card"
						:visible-side="visibleCardsSide"
						:style="{
							'--swing-card-border-color': isAnswerCorrect(card) === null ? undefined : isAnswerCorrect(card) ? 'hsl(134, 81%, 88%)' : 'hsl(354, 81%, 88%)',
						}"
						:progress="progress(card)"
						:previous-progress="previousProgress(card, index)"
						:display-progress="knowledgeProgress && progressVisible"
						:delay-animation="delayAnimation(card, index)"
					>
						<template slot="aside">
							<slot
								name="aside"
								:card="card"
							/>
						</template>
						<transition name="show">
							<!-- Those are correct categories of a card, displayed only when there are no user answers -->
							<div v-if="showAnswers && isAnswerCorrect(card) === null">
								<p
									v-for="(category, categoryIndex) in deckCategories"
									v-if="categoryType === categories.CUSTOM"
									:key="category._id"
									class="c-swipe-direction-indicator"
									:class="`c-swipe-direction-indicator--category${categoryIndex}`"
									:data-qa="`c-swipe-direction-indicator--category${categoryIndex}`"
									:style="{
										'text-decoration': 'underline',
										opacity: (card.category === category._id) ? 1 : 0
									}"
								>
									<span>{{ category.displayName }}</span>
								</p>
							</div>
						</transition>
						<!-- those are answers -->
						<p
							v-if="categoryType === categories.CUSTOM && isAnswerCorrect(card) !== null"
							class="c-swipe-direction-indicator c-swipe-direction-indicator--correct"
							:class="`c-swipe-direction-indicator--${isAnswerCorrect(card) ? 'top' : 'bottom'}`"
						>
							{{ getCorrectCardCategory(card) && getCorrectCardCategory(card).displayName }}
						</p>
						<p
							v-if="categoryType === categories.CUSTOM && isAnswerCorrect(card) === false"
							class="c-swipe-direction-indicator c-swipe-direction-indicator--wrong"
							:class="`c-swipe-direction-indicator--${isAnswerCorrect(card) ? 'bottom' : 'top'}`"
						>
							{{ getAnsweredCardCategory(card).displayName }}
						</p>
					</SwingCard>
				</div>
			</transition-group>

			<div
				v-if="resultDisplayType === resultDisplayTypes.TABLE && questionCardsSorted.length"
				class="result-cards-table-container"
			>
				<table
					class="ui single line small table unstackable result-cards-table-container__table"
				>
					<thead>
						<tr>
							<th>{{ $t('deckPlay.frontSide') }}</th>
							<th>{{ $t('deckPlay.backSide') }}</th>
							<th v-if="shouldDisplayAnswersColumns">
								{{ $t('deckPlay.yourAnswer') }}
							</th>
							<th v-if="shouldDisplayAnswersColumns">
								{{ $t('deckPlay.correctAnswer') }}
							</th>
							<th v-if="knowledgeProgress && progressVisible">
								{{ $t('deckPlay.cardProgress') }}
							</th>
						</tr>
					</thead>
					<transition-group
						name="flip-list"
						tag="tbody"
					>
						<tr
							v-for="card in questionCardsSorted"
							:key="card._id"
							:class="{
								positive: isAnswerCorrect(card) === true,
								negative: isAnswerCorrect(card) === false,
							}"
						>
							<td>{{ card.word }}</td>
							<td>{{ card.translation }}</td>
							<td
								v-if="shouldDisplayAnswersColumns"
								:style="{ 'text-decoration': isAnswerCorrect(card) === false ? 'line-through' : 'none' }"
							>
								{{ getAnsweredCardCategory(card).displayName }}
							</td>
							<td
								v-if="shouldDisplayAnswersColumns"
							>
								{{ isAnswerCorrect(card) !== null ? (getCorrectCardCategory(card) && getCorrectCardCategory(card).displayName) : '' }}
							</td>
							<td v-if="knowledgeProgress && progressVisible">
								{{ progress(card) }}% <small>{{ progressDiffReadable(card) }}</small>
							</td>
						</tr>
					</transition-group>
				</table>
			</div>

			<div
				v-if="questionCardsSorted.length === 0"
				style="display: flex; justify-content: center;"
			>
				<EmptyStateDecks :context="isChoiceCategoryType ? 'questions' :'cards'" />
			</div>
		</div>

		<PopOver
			:show="cardsSettingsPopOverOpened"
			position="bottom"
		>
			<div class="c-popover-body--one">
				<span class="c-popover-body__close-btn-wrapper">
					<button
						type="button"
						class="c-popover-body__close-btn"
						@click="cardsSettingsPopOverOpened = false"
					>
						<i class="ui close icon c-popover-body__close-btn-icon" />
					</button>
				</span>
				<DeckCardsConfiguration
					inline
					:sort-by="sortBy"
					:update-sort-by="updateSortBy"
					:progress-visible="progressVisible"
					:toggle-progress-visible="toggleProgressVisible"
					:visible-cards-side="visibleCardsSide"
					:update-visible-cards-side="updateVisibleCardsSide"
					:result-display-type="resultDisplayType"
					:change-result-display-type="changeResultDisplayType"
					:knowledge-progress="true"
					:toggle-show-answers="toggleShowAnswers"
					:show-answers="showAnswers"
					:disable-controls="disableControlsLocal"
				/>
			</div>
		</PopOver>
	</div>
</template>

<script>
import seedrandom from 'seedrandom';
import { SIDES } from '@/components/swing/swing';
import DeckCardsConfiguration from '@/components/buttons/DeckCardsConfiguration';
import { categories, defaultCategories } from '@/assets/js/lists/categories';
import PopOver from '@/components/PopOver';
import { sortCardsBy } from '@/assets/js/utils/sorting';
import { resultDisplayTypes } from '@/components/buttons/DeckCardsConfiguration';
import VueSwing from '@/components/VueSwing';
import SwingCard from '@/components/swing/SwingCard';
import { calculatePreviousKnownFactor, progressPercent } from '@/assets/js/dataServices/knowledgeFactor';
import { progressDiffReadable } from '@/components/progress/Progress';
import { events, logAnalyticsEvent } from '@/assets/js/utils/analytics';
import { intersectionHandlerOnce } from '@/assets/js/utils/dom';
import AnswersCheckboxes from '@/components/deckPlay/AnswersCheckboxes';
import AnswersRadio from '@/components/deckPlay/AnswersRadio';
import Progress from '@/components/progress/Progress';
import { isChoiceCategoryType } from '@/assets/js/lists/List';
import EmptyStateDecks from '@/components/EmptyStateDecks';

const { Direction } = VueSwing;

const getRandomInt = (value, min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(value * (max - min + 1)) + min;
};

export default {
	name: 'ResultsCards',
	components: {
		EmptyStateDecks,
		Progress,
		AnswersRadio,
		AnswersCheckboxes,
		SwingCard,
		DeckCardsConfiguration,
		PopOver,
},
	props: {
		deck: {
			type: Object,
		},
		generatedCategories: {
			type: Array,
			required: false,
		},
		cards: {
			type: Array,
		},
		answers: {
			type: Object,
			default: () => ({}),
		},
		seed: {
			type: String,
			default: '',
		},
		showCardsConfiguration: {
			type: Boolean,
			default: true,
		},
		scrollable: {
			type: Boolean,
			default: false,
		},
		overwrites: {
			type: Object,
			default: () => ({}),
		},
		disableControls: {
			type: Object,
			default: undefined,
		},
		hideAnswersColumnsInTable: false,
		displayProgressDifference: false,
		darkBackground: false,
	},
	data: props => ({
		categories,
		cardsSettingsPopOverOpened: false,
		sortBy: 'no_sorting',
		progressVisible: true,
		visibleCardsSide: SIDES.FRONT,
		resultDisplayType: resultDisplayTypes.CARDS,
		showAnswers: true, // Shall this be true or false?
		resultDisplayTypes,
		visibleCards: [],
		displayedCards: 0,
		...props.overwrites,
	}),
	computed: {
		disableControlsLocal() {
			const overwrites = {};

			if (this.isChoiceCategoryType) {
				overwrites.flipAllCardsTo = true;
				overwrites.displayAs = true;
			}

			if (this.categoryType === categories.DEFAULT) {
				overwrites.answersVisibility = true;
			}
			return {
				...this.disableControls,
				...overwrites,
			};
		},
		knowledgeProgress() {
			return this.$store.getters.feature('knowledgeProgress');
		},
		shouldDisplayAnswersColumns() {
			return !this.hideAnswersColumnsInTable;
		},
		questionCardsSorted() {
			return sortCardsBy(this.sortBy, this.cards);
		},
		categoryType() {
			return this.deck.categoryType;
		},
		deckCategories() {
			return this.generatedCategories || this.deck.categories;
		},
		cardCategories() {
			return (card) => {
				let deckCategories;
				if (this.deckCategories && this.deckCategories.length) {
					deckCategories = this.deckCategories;
				}

				return card.categories || deckCategories || defaultCategories(this.$t.bind(this));
			};
		},
		getAnsweredCardCategory() {
			return (card) => {
				const answer = this.answers[card._id];

				return (this.cardCategories(card) || []).find(category => answer === category._id) || {};
			};
		},
		getCorrectCardCategory() {
			return card => (this.cardCategories(card) || []).find(category => card.category === category._id);
		},
		answersToOptions() {
			return card => card.answers.map(({ _id, displayName }) => ({ key: _id, name: displayName }));
		},
		isChoiceCategoryType() {
			return isChoiceCategoryType(this.categoryType);
		},
	},
	mounted() {
		(this.$refs.cards || []).forEach((card) => {
			intersectionHandlerOnce(card, (card) => {
				if (this.displayedCards === 0) {
					logAnalyticsEvent(...events.firstResultsCardDisplay);
				}
				this.displayedCards++;
				this.visibleCards.push(card);
			});
		});
	},
	methods: {
		seedrandom,
		getRandomInt,
		stylee(suffix) {
			if (this.seed === '') {
				return {};
			}

			const seed = this.seed + suffix;
			return {
				'--card-rotate': `${this.getRandomInt(this.seedrandom(`${seed}rotate`)(), -4, 4)}deg`,
				'--card-offset-x': `${this.getRandomInt(this.seedrandom(`${seed}x`)(), -4, 4)}px`,
				'--card-offset-y': `${this.getRandomInt(this.seedrandom(`${seed}y`)(), -4, 4)}px`,
			};
		},
		updateSortBy(sortBy) {
			this.sortBy = sortBy;
		},
		toggleProgressVisible() {
			this.progressVisible = !this.progressVisible;
		},
		updateVisibleCardsSide(side) {
			this.visibleCardsSide = side;
		},
		changeResultDisplayType(type) {
			this.resultDisplayType = type;
		},
		isAnswerCorrect(card) {
			const answer = this.answers[card._id];

			if (!answer) {
				return null;
			}

			if (this.categoryType === categories.DEFAULT) {
				return answer === Direction.RIGHT;
			}

			return card.category === answer;
		},
		progress(card) {
			return progressPercent(card.knownNumber);
		},
		previousProgress(card) {
			if (this.displayProgressDifference) {
				return progressPercent(calculatePreviousKnownFactor(card.guesses));
			}

			return progressPercent(card.knownNumber);
		},
		progressDiff(card) {
			return this.progress(card) - this.previousProgress(card);
		},
		progressDiffReadable(card) {
			return progressDiffReadable(this.progressDiff(card));
		},
		delayAnimation(card, index) {
			// visibleCards not defined
			return !this.visibleCards.includes(((this.$refs || {}).cards || [])[index]);
		},
		toggleShowAnswers() {
			this.showAnswers = !this.showAnswers;
		},
	},
};
</script>

<style scoped lang="scss">
	.c-results-cards {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.c-results-cards__scrollable-content {
		overflow: auto;
		flex-grow: 1;
	}

	.result-cards-table-container {
		margin-top: var(--spacing-16);
		overflow-x: auto;

		&__table {
			min-width: 400px;
		}
	}

	.result-cards-grid {
		--results-cards-grid-min-card-width: 130px;

		padding-bottom: 3px; /* For the card shadow */

		margin-top: var(--spacing-16);
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(var(--results-cards-grid-min-card-width), 2fr));
		grid-column-gap: var(--spacing-8);
		grid-row-gap: var(--spacing-8);
	}

	.result-cards-grid--answers {
		--results-cards-grid-min-card-width: 235px;
	}

	.deck-cards-configuration__button {
		display: none;
	}

	.deck-cards-configuration--knowledge-progress .deck-cards-configuration__button {
		display: block;
		@media only screen and (min-width: 570px) {
			display: none;
		}
	}

	.deck-cards-configuration__container {
		display: block;
	}

	.deck-cards-configuration--knowledge-progress .deck-cards-configuration__container {
		display: none;
		@media only screen and (min-width: 570px) {
			display: block;
			margin-bottom: var(--spacing-16);
		}
	}

	.flip-list-move {
		transition: transform 1s;
	}

	.show-enter-active, .show-leave-active {
		transition: opacity .5s ease;
	}

	.show-enter, .show-leave-to {
		opacity: 0;
	}

	.c-results-card {
		height: 100%;
		transform: rotate(var(--card-rotate)) translateX(var(--card-offset-x)) translateX(var(--card-offset-y));
	}

	.c-results-card__inner {
		height: 100%;
	}
</style>
<style>
:root {
	--card-rotate: 0deg;
	--card-offset-x: 0;
	--card-offset-y: 0;
}
</style>
